import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ProjectMainLayout } from 'layouts/ProjectMainLayout';
import { PROJECTS } from 'constants/projects';
import { GridFour } from 'components/GridFour';

export const TikkurilaView = () => {
    const images = useStaticQuery(graphql`
        query TikkurilaImages {
            upperLeft: file(name: { eq: "tikkurila-upper-left" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            upperRight: file(name: { eq: "tikkurila-upper-right" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            lowerLeft: file(name: { eq: "tikkurila-lower-left" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
            lowerRight: file(name: { eq: "tikkurila-lower-right" }) {
                childImageSharp {
                    gatsbyImageData(quality: 100)
                }
            }
        }
    `);

    return (
        <ProjectMainLayout projectName={PROJECTS.tikkurila.name}>
            <GridFour {...images} projectName={PROJECTS.tikkurila.name} />
        </ProjectMainLayout>
    );
};
